// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
// import 'select2';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
import '../blocks/gutenberg/acf-example-block/index.js'; import '../blocks/gutenberg/acf-hero-slider-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
// function resizeVideo() {
//   let $holder = $('.videoHolder');
//   $holder.each(function () {
//     let $that = $(this);
//     let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
//     let width = parseFloat(ratio.split(':')[0]);
//     let height = parseFloat(ratio.split(':')[1]);
//     $that.find('.video').each(function () {
//       if ($that.width() / width > $that.height() / height) {
//         $(this).css({
//           width: '100%',
//           height: 'auto',
//         });
//       } else {
//         $(this).css({
//           width: ($that.height() * width) / height,
//           height: '100%',
//         });
//       }
//     });
//   });
// }

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  if ($('.side-image-block__slider').length) {
    $('.side-image-block__slider').slick({
      dots: false,
      infinite: true,
      // speed: 3000,
      fade: false,
      arrows: false,
      cssEase: 'linear',
      autoplay: true,
    });
  }
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  /**
   * Style contact form
   */
  if ($('.main-content--contact .gform_button').length) {
    $('.main-content--contact .gform_button').html(
      '<span class="red-block-button__content">' +
        $('.main-content--contact .gform_button').text() +
        '</span>'
    );
  }
  // if ($('.main-content--contact .gfield_select').length) {
  //   $('.main-content--contact .gfield_select').select2({
  //     minimumResultsForSearch: -1,
  //   });
  // }
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }
  /**
   * Dynamic of Header search form
   */
  if ($('.search-item').length) {
    $('.search-item').on('click', (e) => {
      e.stopPropagation();
      if ($(e.target).is('a')) {
        e.preventDefault();
      }
      $(e.target).closest('.search-item').addClass('active-form');
      $(e.target).find('.search__input').focus();
    });
    if ($('.search-item').find('.search__input').length) {
      $('.search-item')
        .find('.search__input')
        .on('focusout', function (e) {
          if (
            e.relatedTarget &&
            e.relatedTarget.classList.contains('search__submit')
          ) {
            $(this).focus();
          } else {
            $(this).closest('.search-item').removeClass('active-form');
          }
        });
    }
    if ($('.search-item').find('.search_submit').length) {
      $('.search-item .search_submit').on('click', function () {
        $('.search-item form.search').submit();
      });
    }
  }
  if ($('.header__search-btn').length) {
    $('.header__search-btn').on('click', function (e) {
      e.preventDefault();
      $(this).closest('.header__search-form-container').addClass('active');
      $(this)
        .closest('.header__search-form-container')
        .find('.search__input')
        .focus();
    });
  }
  if ($('.header__search-form-container .search__input').length) {
    $('.header__search-form-container .search__input').on(
      'focusout',
      function (e) {
        if (
          e.relatedTarget &&
          e.relatedTarget.classList.contains('search__submit')
        ) {
          $(this).focus();
        } else {
          $(this)
            .closest('.header__search-form-container')
            .removeClass('active');
        }
      }
    );
  }
  /**
   * Directory filter
   */
  if ($('.directory__filter-button').length) {
    $('.directory__filter-button').on('click', function (e) {
      if ($(e.target).closest('.directory__filter-button').hasClass('active')) {
        return;
      }
      const slug = $(e.target)
        .closest('.directory__filter-button')
        .data('slug');
      /* global ajax_object */
      $.ajax({
        url: ajax_object.ajax_url,
        type: 'GET',
        data: {
          action: 'directory_filter',
          nonce: ajax_object.nonce,
          slug,
        },
        beforeSend: function () {
          $('.directory__filter-button').removeClass('active');
        },
        success: function (resp) {
          if ($('.directory__content').length && resp) {
            console.log(resp);
            $('.directory__content').html(resp);
          }
        },
        complete: function () {
          $(`.directory__filter-select option[value=${slug}]`).attr(
            'selected',
            'selected'
          );
          $(e.target).closest('.directory__filter-button').addClass('active');
        },
        error: function (err) {
          console.log(err);
        },
      });
    });
  }
  if ($('.directory__filter-select').length) {
    $('.directory__filter-select').on('change', function () {
      const slug = $(this).find(':selected').val();
      $.ajax({
        url: ajax_object.ajax_url,
        type: 'GET',
        data: {
          action: 'directory_filter',
          nonce: ajax_object.nonce,
          slug,
        },
        beforeSend: function () {
          $('.directory__filter-button').removeClass('active');
        },
        success: function (resp) {
          if ($('.directory__content').length && resp) {
            $('.directory__content').html(resp);
          }
        },
        complete: function () {
          $(`.directory__filter-button[data-slug=${slug}]`).addClass('active');
        },
        error: function (err) {
          console.log(err);
        },
      });
    });
  }
  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('header.header').toggleClass('menu-open');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('header.header').removeClass('menu-open');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  // resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  // resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
