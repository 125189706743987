import $ from 'jquery';
import 'slick-carousel';
$(document).ready(function () {
  acfHeroSliderBlock();
});

function acfHeroSliderBlock() {
  $('.js-acf-hero-slider-block').each(function () {
    const $leftSlider = $(this).find('.hero-slider__left-slider');
    const $rightSlider = $(this).find('.hero-slider__right-slider');
    if ($leftSlider.length && $rightSlider.length) {
      $leftSlider.slick({
        arrows: false,
        asNavFor: $rightSlider,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 670,
            settings: {
              vertical: true,
              verticalSwiping: true,
            },
          },
        ],
      });
      $rightSlider.slick({
        arrows: false,
        asNavFor: $leftSlider,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: true,
        autoplay: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 670,
            settings: {
              vertical: true,
              verticalSwiping: true,
              rtl: false,
            },
          },
        ],
      });
    }
  });
}
